#furo-sidebar-ad-placement {
  padding: var(--sidebar-item-spacing-vertical) var(--sidebar-item-spacing-horizontal);
}
#furo-sidebar-ad-placement .ethical-sidebar {
  border: none;
  box-shadow: none;
  background: var(--color-background-secondary);
}
#furo-sidebar-ad-placement .ethical-sidebar:hover {
  background: var(--color-background-hover);
}
#furo-sidebar-ad-placement .ethical-sidebar a {
  color: var(--color-foreground-primary);
}
#furo-sidebar-ad-placement .ethical-callout a {
  color: var(--color-foreground-secondary) !important;
}

button.copybtn {
  width: 1.25rem;
  height: 1.25rem;
}

body {
  --tabs--label-text: var(--color-foreground-muted);
  --tabs--label-text--active: var(--color-brand-content);
  --tabs--label-text--hover: var(--color-brand-content);
  --tabs--label-background--active: transparent;
  --tabs--label-background--hover: var(--color-background-secondary);
  --tabs--border: var(--color-background-border);
}

[role=main] .container {
  max-width: initial;
  padding-left: initial;
  padding-right: initial;
}

.shadow.docutils {
  border: none;
  box-shadow: 0 0.2rem 0.5rem rgba(0, 0, 0, 0.05), 0 0 0.0625rem rgba(0, 0, 0, 0.1) !important;
}

.sphinx-bs .card {
  background-color: var(--color-background-secondary);
  color: var(--color-foreground);
}