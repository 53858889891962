// This file contains styles to tweak sphinx-panels to work well with Furo.

// sphinx-panels includes Bootstrap 4, which uses .container which can conflict
// with docutils' `.. container::` directive.
[role="main"] .container
  max-width: initial
  padding-left: initial
  padding-right: initial

// Make the panels look nicer!
.shadow.docutils
  border: none
  box-shadow: 0 0.2rem 0.5rem rgba(0, 0, 0, 0.05), 0 0 0.0625rem rgba(0, 0, 0, 0.1) !important

// Make panel colors respond to dark mode
.sphinx-bs .card
  background-color: var(--color-background-secondary)
  color: var(--color-foreground)
